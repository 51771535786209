<template>
  <v-container fluid>
    <v-tabs>
      <v-tab :to="{name: 'admin.departments'}">Подразделения</v-tab>
      <v-tab :to="{name: 'admin.groups'}">Студ. группы</v-tab>
      <v-tab v-if="$store.getters.checkPermission('can_manage_users')" :to="{name: 'admin.users'}">Пользователи</v-tab>
      <v-tab v-if="$store.getters.checkPermission('can_manage_pools')" :to="{name: 'admin.opd'}">ОПД</v-tab>
    </v-tabs>
    <router-view/>
  </v-container>
</template>

<script>
export default {
  name: "AdminIndexComponent"
}
</script>

<style scoped>

</style>
